<template>
  <div class="addBankCard">
    <!-- 添加银行卡 -->
    <div class="pagetitle">添加{{ title }}</div>
    <template v-if="type == 'bank'">
      <div class="text1">
        <img src="@/assets/withdraw/setting1.png" alt="" />
        贪吃商场全力保护你的信息安全
      </div>
      <div class="text2">输入卡号添加<span> (当前仅支持对私借记卡，请使用Ⅰ类卡)</span></div>
      <van-form submit="">
        <van-field
          v-model="cardInfo.accountNo"
          label="卡号"
          placeholder="请输入卡号"
          :rules="[{ required: true, message: '' }]"
        />
        <template v-if="showPhone">
          <van-field
            :value="`${cardInfo.bankName} ${cardInfo.cardTypeName}`"
            type="text"
            label="卡片类型"
            readonly
          />
          <!-- <van-field
            v-model="cardInfo.bankCardPhoneNo"
            type="text"
            label="手机号"
            placeholder="请输入手机号"
            :rules="[{ required: true, message: '' }]"
          >
            <template #button>
              <van-button
                size="small"
                type="danger"
                class="btn"
                @click="getCaptcha"
                :disabled="send || cardInfo.bankCardPhoneNo === ''"
              >
                <div style="display: flex; align-items: center">
                  {{ !send ? '发送验证码' : '已发送' }}
                  <template v-if="send">
                    (<van-count-down
                      style="color: #fff"
                      :time="3 * 60 * 1000"
                      format="ss"
                      @finish="send = false"
                    />)
                  </template>
                </div>
              </van-button>
            </template>
          </van-field>
          <van-field
            v-model="cardInfo.captcha"
            type="text"
            label="验证码"
            placeholder="请输入验证码"
            :rules="[{ required: true, message: '' }]"
          /> -->
        </template>
        <div class="nextStep">
          <van-button block size="small" type="danger" v-if="!showPhone" @click="getCardInfo"
            >下一步</van-button
          >
          <van-button
            block
            size="small"
            type="danger"
            v-else
            @click="onSubmit"
            >确定</van-button
          >
          <div style="margin-top: 10px"></div>
          <van-button block plain size="small" type="danger" @click="$router.go(-1)"
            >返回</van-button
          >
        </div>
      </van-form>
    </template>
    <!-- 添加支付宝 -->
    <template v-else-if="type == 'alipay'">
      <div class="text1">
        <img src="@/assets/withdraw/setting1.png" alt="" />
        贪吃商场全力保护你的信息安全
      </div>
      <div class="text2">输入支付宝账号<span> </span></div>
      <van-form @submit="onSubmit">
        <van-field
          v-model="cardInfo.accountNo"
          label="账号"
          placeholder="请输入支付宝账号"
          :rules="[{ required: true, message: '' }]"
        />
        <div class="nextStep">
          <van-button block size="small" type="danger" native-type="submit">确定绑定</van-button>
          <div style="margin-top: 10px"></div>
          <van-button block plain size="small" type="danger" @click="$router.go(-1)"
            >返回</van-button
          >
        </div>
      </van-form>
    </template>
  </div>
</template>
<script>
import topbar from '@/component/topbar.vue'
export default {
  components: { topbar },
  name: '',
  data() {
    // 6217858000087239072
    return {
      type: '',
      cardInfo: {
        accountNo: '',
        accountType: 1,
        bankCardPhoneNo: '',
        captcha: '',
        bankCode: '',
        bankCardTypeName: '',
        verifyVoucher: '',
        bankName: ''
      },
      send: false,
      title: ''
    }
  },
  computed: {
    showPhone() {
      return this.cardInfo.bankCode && this.cardInfo.cardTypeName && this.cardInfo.bankName
    },
  },
  created() {
    this.type = this.$route.query.type
  },
  watch: {
    type(n) {
      if (n == 'bank') {
        this.cardInfo.accountType = 1
        this.$route.meta.title = '添加银行卡'
        this.title = '银行卡'
      } else if (n == 'alipay') {
        this.cardInfo.accountType = 2
        this.$route.meta.title = '添加支付宝'
        this.title = '支付宝'
      }
    }
  },
  methods: {
    getCardInfo() {
      this.$http
        .get('/boom-center-third-party-service/app/yunzhanghu/bankCard/info', {
          params: {
            bankCardNo: this.cardInfo.accountNo
          }
        })
        .then(res => {
          this.cardInfo.bankCode = res.bankCode
          this.cardInfo.cardTypeName = res.cardTypeName
          this.cardInfo.bankName = res.bankName
        })
        .catch(err => {
          this.$notify({ type: 'danger', message: res.msg })
        })
    },
    getCaptcha() {
      this.send = true
      this.$http
        .post(`/boom-mix-biz-service/app/distribution/member/account/bankCard/verify/sendCaptcha`, {
          bankCardNo: this.cardInfo.accountNo,
          bankCardPhoneNo: this.cardInfo.bankCardPhoneNo
        })
        .then(res => {
          this.cardInfo.verifyVoucher = res
        })
        .catch(err => {
          this.send = false
          this.$dialog.alert({
            message: err.msg || '服务器內部错误'
          })
        })
    },
    onSubmit() {
      // accountNo	string
      // 账户号码（银行卡号、支付宝账号）
      // accountType	integer($int32)
      // 账户类型（1-银行卡、2-支付宝）
      // bankCardPhoneNo	string
      // 银行预留手机号
      // bankCardTypeName	string
      // 银行卡类型名称
      // bankCode	string
      // 银行代码
      // bankName	string
      // 银行名称
      // captcha	string
      // 短信验证码
      // verifyVoucher	string
      // 交易凭证
      this.$http
        .post('/boom-mix-biz-service/app/distribution/member/account/create', {
          ...this.cardInfo
        })
        .then(res => {
          this.$toast.success('新增成功')
          this.$router.replace(`/withdraw/setting?type=${this.type}`)
        })
        .catch(err => {
          this.$notify({ type: 'warning', message: err.msg })
        })
        .finally(() => {})
    }
  }
}
</script>
<style lang='less' scoped>
.addBankCard {
  padding: 100px 5px 0;
  .pagetitle {
    text-align: center;
    height: 31px;
    font-size: 22px;
    font-weight: 500;
    color: #333333;
    line-height: 26px;
  }
  .text1 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 16px;
    margin-top: 6px;
    img {
      width: 18px;
      height: 18px;
    }
  }
  .text2 {
    height: 25px;
    font-size: 18px;
    font-weight: 500;
    color: #333;
    line-height: 21px;
    margin: 29px 0 16px;
    padding: 0 15px;
    span {
      font-size: 14px;
      color: #999;
    }
  }
  .nextStep {
    padding: 0 15px;
    margin-top: 26px;
  }
}
</style>
